import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import sum from 'lodash/sum';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import _ from 'lodash';
import { darken } from 'polished';

import * as API from '../apis';
import breakpoints from '../styles/breakpoints';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Spinner from '../components/Spinner';
import Markdown from '../components/Markdown';
import PricingBox from '../components/PricingBox';
import PurchaseForm from '../components/PurchaseForm';
import MutedText from '../components/MutedText';
import Delimiter from '../components/Delimiter';
import PromoList from '../components/PromoList';

const CardContainer = styled.div`
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
`;

const Card = styled.div`
  margin: 0 auto;
  padding: 5rem 1rem;

  @media (max-width: ${breakpoints.md}) {
    padding: 1.5rem 1rem;
  }
`;

const InfoCard = styled(Card)`
  max-width: 450px;
  display: inline-block;
`;

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const LoadingIcon = styled(Spinner).attrs({ color: '#10abe2' })`
  width: 1.125em;
  height: 1.125em;
`;

const Header = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Body = styled.section`
  padding: 1.5rem 3rem 3rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: auto;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const PricingLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  background: #10abe2;
  ${props => (props.shadow ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)' : '')}
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  padding: 0 1rem;

  &:hover {
    color: white;
    background: ${darken(0.04, '#10abe2')};
  }
`;

const PricingTable = styled.table`
  white-space: nowrap;
`;

const PricingCell = styled.td``;

const PricingCard = styled(Card)`
  max-width: 700px;
  padding-top: 0;
`;

const SuccessCard = styled(Card)`
  max-width: 700px;
`;

const ActionButton = styled(MutedText)`
  margin-top: 1rem;
  cursor: pointer;
`;

const PromoHeader = styled.section`
  padding: 2rem 0 1.5rem;
  font-weight: 600;
  text-align: left;
  background-color: #ffffff;
`;

function PurchaseSubscriptionPage() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [promos, setPromos] = useState([]);
  const [appliedPromoCode, setPromoCode] = useState();
  const [trialSubscriptions, setTrialSubscriptions] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState({});
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [finalPrice, setFinalPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = value => {
    setSelectedSubs({
      ...selectedSubs,
      [value.code]: value,
    });
  };

  const handleSuccess = () => {
    setSubmitted(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(false);
        const subscriptionResponse = await API.getSubscriptions();
        const promoResponse = await API.getAllPromos();
        setSubscriptions(_.filter(subscriptionResponse, sub => !sub.isTrial));
        setTrialSubscriptions(_.filter(subscriptionResponse, sub => sub.isTrial));
        setPromos(_.filter(promoResponse.promos, promo => promo.appliedFor === 'purchase'));
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const validOrders = values(selectedSubs).filter(({ type }) => type);
    const tempTotal = sum(validOrders.map(({ price }) => price));
    const tempFinalPrice = sum(
      validOrders.map(({ price, type, code }) => {
        const appliedPromo = _.find(promos, pro => pro.code === appliedPromoCode);
        if (appliedPromo && appliedPromo.subscription.code === code) {
          switch (type) {
            case '3-thang':
              return appliedPromo.pricing3month || price;
            case '5-thang':
              return appliedPromo.pricing5month || price;
            case '12-thang':
              return appliedPromo.pricing12month || price;
            default:
              break;
          }
        }

        return price;
      })
    );
    setTotal(tempTotal);
    if (tempTotal === tempFinalPrice) {
      setFinalPrice(null);
    } else {
      setFinalPrice(tempFinalPrice);
    }
    setOrders(
      validOrders.map(order => {
        const appliedPromo = _.find(promos, pro => pro.code === appliedPromoCode);
        if (appliedPromo && appliedPromo.subscription.code === order.code) {
          switch (order.type) {
            case '3-thang':
              if (!isNil(appliedPromo.pricing3month)) {
                return {
                  ...order,
                  pricePromo: { price: appliedPromo.pricing3month, code: appliedPromoCode },
                };
              }
              break;
            case '5-thang':
              if (!isNil(appliedPromo.pricing5month)) {
                return {
                  ...order,
                  pricePromo: { price: appliedPromo.pricing5month, code: appliedPromoCode },
                };
              }
              break;
            case '12-thang':
              if (!isNil(appliedPromo.pricing12month)) {
                return {
                  ...order,
                  pricePromo: { price: appliedPromo.pricing12month, code: appliedPromoCode },
                };
              }
              break;
            default:
              break;
          }
        }

        return order;
      })
    );
  }, [selectedSubs, appliedPromoCode, promos]);

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Các gói cước" />
      {loading && <LoadingIcon />}
      {submitted && (
        <SuccessCard>
          <Body>
            <center>
              Cảm ơn bạn đã đăng ký mua gói! Trạng sẽ liên hệ lại với bạn trong thời gian sớm nhất
              để hướng dẫn bạn cách thức thanh toán. Vui lòng kiểm tra email để biết thêm thông tin
              chi tiết.
              <ActionButton onClick={() => navigate('/')}>Về trang chủ</ActionButton>
            </center>
          </Body>
        </SuccessCard>
      )}
      {!loading && !submitted && (
        <>
          <CardContainer>
            {trialSubscriptions.map(sub => (
              <InfoCard key={sub.code}>
                <Header>{`Gói ${sub.name}`}</Header>
                <Body>
                  <Markdown source={sub.description ? sub.description.md : ''} />
                  <center>
                    <h4>Miễn phí khi đăng ký tài khoản!</h4>
                  </center>
                </Body>
              </InfoCard>
            ))}
          </CardContainer>
          <Delimiter />
          <CardContainer>
            {subscriptions.map(sub => (
              <InfoCard key={sub.code}>
                <Header>{`Gói ${sub.name}`}</Header>
                <Body>
                  <Markdown source={sub.description ? sub.description.md : ''} />
                  <center>
                    <PricingLink href="#select-subscriptions">Xem giá thành</PricingLink>
                  </center>
                </Body>
              </InfoCard>
            ))}
          </CardContainer>
          <PricingCard id="select-subscriptions">
            <Header>Giá thành</Header>
            <Body>
              <PricingTable>
                <tbody>
                  <tr>
                    <td />
                    <td />
                    <td>
                      <b>3 tháng</b>
                    </td>
                    <td>
                      <b>5 tháng</b>
                    </td>
                    <td>
                      <b>12 tháng</b>
                    </td>
                  </tr>
                  {subscriptions.map(sub => (
                    <tr key={sub.code}>
                      <td>
                        <b>{`Gói ${sub.name}`}</b>
                      </td>
                      <PricingCell>
                        <PricingBox
                          code={sub.code}
                          type={null}
                          price={null}
                          onSelect={handleChange}
                          defaultChecked
                        />
                      </PricingCell>
                      <PricingCell>
                        <PricingBox
                          code={sub.code}
                          type="3-thang"
                          price={sub.pricing3month}
                          onSelect={handleChange}
                        />
                      </PricingCell>
                      <PricingCell>
                        <PricingBox
                          code={sub.code}
                          type="5-thang"
                          price={sub.pricing5month}
                          onSelect={handleChange}
                        />
                      </PricingCell>
                      <PricingCell>
                        <PricingBox
                          code={sub.code}
                          type="12-thang"
                          price={sub.pricing12month}
                          onSelect={handleChange}
                        />
                      </PricingCell>
                    </tr>
                  ))}
                  {promos.length > 0 && (
                    <tr>
                      <td colSpan={5}>
                        <PromoHeader>Bạn có thể chọn một trong những khuyến mãi sau:</PromoHeader>
                        <PromoList
                          promos={promos}
                          promoCode={appliedPromoCode}
                          listType="newline"
                          selectable
                          onSelected={p => setPromoCode(p)}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={3}>Tổng:</td>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      <PricingBox price={total} pricePromo={{ price: finalPrice }} />
                    </td>
                  </tr>
                </tbody>
              </PricingTable>

              <PurchaseForm orders={orders} onSuccess={handleSuccess} />
            </Body>
          </PricingCard>
        </>
      )}
    </Layout>
  );
}

export default PurchaseSubscriptionPage;
