import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga';

import * as API from '../apis';
import { useUser } from '../utils/useAuth';
import { ACTION, CATEGORY } from '../constants/ga';
import Button from './Button';
import Input from './Input';
import Message from './Message';

const Field = styled.div`
  margin-bottom: 1.5rem;
`;

function PurchaseForm({ orders, onSuccess }) {
  const user = useUser();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [invoiceInfo, setInvoiceInfo] = useState('');
  const [note, setNote] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = error => {
    setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
    console.error(error);
  };

  const submit = async () => {
    try {
      setSubmitting(true);
      setErrorMessage('');
      const response = await API.purchaseSubscription({
        phoneNumber,
        packages: orders,
        invoiceInfo,
        note,
      });
      setSubmitting(false);
      if (response && response.id) {
        if (onSuccess) {
          onSuccess(response);
        }
      } else {
        handleError(response);
      }
    } catch (ex) {
      setSubmitting(false);
      handleError(ex);
    }

    ReactGA.event({
      category: CATEGORY.USER_MANAGEMENT,
      action: ACTION.PURCHASE_SUBSCRIPTION,
      value: orders,
    });
  };

  if (isEmpty(orders)) {
    return null;
  }

  if (!user) {
    return 'Bạn cần phải đăng nhập để mua gói';
  }

  return (
    <>
      <Field>
        <Input
          label="Số điện thoại"
          placeholder="Số điện thoại"
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
          disabled={submitting}
        />
      </Field>
      <Field>
        <Input
          label="Thông tin xuất hoá đơn"
          placeholder="Vui lòng nhập nếu bạn muốn xuất hoá đơn"
          value={invoiceInfo}
          onChange={event => setInvoiceInfo(event.target.value)}
          disabled={submitting}
          type="textarea"
          height="120px"
        />
      </Field>
      <Field>
        <Input
          label="Ghi chú"
          placeholder="Nhập ghi chú"
          value={note}
          onChange={event => setNote(event.target.value)}
          disabled={submitting}
          type="textarea"
          height="120px"
        />
      </Field>
      <Button loading={submitting} disabled={submitting} onClick={submit}>
        Hoàn tất
      </Button>
      {errorMessage && <Message error>{errorMessage}</Message>}
    </>
  );
}

PurchaseForm.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      type: PropTypes.string,
      price: PropTypes.number,
    })
  ),
  onSuccess: PropTypes.func,
};

PurchaseForm.defaultProps = {
  orders: [],
  onSuccess: () => {},
};

export default PurchaseForm;
