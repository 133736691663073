import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import _ from 'lodash';

const RadioButton = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
  margin-top: -2px;
`;

const Currency = styled.span`
  font-size: 0.5rem;
`;

const Price = styled.span`
  ${prop => prop.strikethough && `text-decoration: line-through;`}
`;

const PricePromo = styled.span`
  color: red;
  font-weight: bold;
`;

const PricingBox = ({ code, type, price, pricePromo, onSelect, defaultChecked }) => {
  const hasPromo = pricePromo && !_.isNil(pricePromo.price) && pricePromo.price >= 0;

  return (
    <>
      <script src="https://www.geogebra.org/apps/deployggb.js" />
      {code && (
        <RadioButton
          name={code}
          onChange={() =>
            onSelect({
              code,
              type,
              price: price || 0,
              pricePromo: hasPromo ? pricePromo || 0 : null,
            })
          }
          defaultChecked={defaultChecked}
        />
      )}
      {!_.isNil(price) && (
        <Price strikethough={hasPromo}>
          <CurrencyFormat value={price} displayType="text" thousandSeparator="," />
          <Currency> VNĐ</Currency>
        </Price>
      )}
      {hasPromo && (
        <>
          <br />
          <PricePromo>
            <CurrencyFormat value={pricePromo.price} displayType="text" thousandSeparator="," />
            <Currency> VNĐ</Currency>
          </PricePromo>
        </>
      )}
    </>
  );
};

PricingBox.propTypes = {
  code: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.number,
  pricePromo: PropTypes.shape({
    code: PropTypes.string,
    price: PropTypes.number,
  }),
  onSelect: PropTypes.func,
  defaultChecked: PropTypes.bool,
};

PricingBox.defaultProps = {
  code: '',
  type: '',
  price: 0,
  pricePromo: null,
  onSelect: () => undefined,
  defaultChecked: false,
};

export default PricingBox;
